import { AddressField } from '@retire/constants'
import { DEFAULT_COUNTRY_CODE } from '@retire/constants/telephone'
import type { TAddressData } from '@retire/types'
import type { Property } from 'csstype'
import type { CountryCode } from 'libphonenumber-js'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import { DEFAULT_DECIMAL_DELIMITER, DEFAULT_PRECISION, DEFAULT_THOUSAND_DELIMITER, toCurrency } from '../currency'

export const DEFAULT_TEXT_ALIGN: Property.TextAlign = 'left'

// from "this is a string" to "This Is A String"
export const sentenceCase = (text: string) => text.replace(/^\w/, textElt => textElt.toUpperCase())

export const protectString = (word: string) =>
  word
    .split('')
    .map(() => String.fromCharCode(183))
    .join('')

export const truncateString = (str: string, numOfChars: number, textOverflow = '...') => {
  if (str.length < numOfChars) {
    return str
  }
  return str.slice(0, numOfChars).concat(textOverflow)
}

export const protectEmail = (email: string) => {
  const protectSegment = (segment: string) =>
    segment
      .split('')
      .map((char, idx) => (idx === 0 || idx === segment.length - 1 ? char : '*'))
      .join('')

  return email
    .split('@')
    .map((segment, index) =>
      index === 0
        ? protectSegment(segment)
        : segment
            .split('.')
            .map((innerSegment, innerIndex, array) =>
              innerIndex === array.length - 1 ? innerSegment : protectSegment(innerSegment)
            )
            .join('.')
    )
    .join('@')
}

// based on i18next Interpolation escape method
// escape string, to avoid XSS attack, but skip apostrophe
const _entityMap = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  '/': '&#x2F;',
}
export const escapeExceptApostrophe = (data: string) => data.replace(/["&/<>]/g, char => _entityMap[char] as string)

export const parseStringToFloatWithPrecision = (
  value: string,
  {
    decimalDelimiter = DEFAULT_DECIMAL_DELIMITER,
    precision = DEFAULT_PRECISION,
    forcePrecision = false, // force padding-right with '0' to fit requested precision
  } = {}
) =>
  value
    ?.replace(new RegExp(`[^\\d${decimalDelimiter}]`, 'g'), '')
    .split(decimalDelimiter, 2)
    .map((splitPart, index) => {
      if (splitPart.length) {
        if (index === 0) {
          return Number(splitPart)
        }
        if (index === 1) {
          let decimals = splitPart.slice(0, precision)
          decimals = forcePrecision ? decimals.padEnd(precision, '0') : decimals
          return decimals
        }
      }
      return splitPart
    })
    .join(precision ? decimalDelimiter : '') || ''

export const parseStringToDecimalWithDelimiters = (
  text: string,
  {
    decimalDelimiter = DEFAULT_DECIMAL_DELIMITER,
    thousandDelimiter = DEFAULT_THOUSAND_DELIMITER,
    precision = DEFAULT_PRECISION,
    forcePrecision = false, // force padding-right with '0' to fit requested precision
  } = {}
) =>
  parseStringToFloatWithPrecision(text, { decimalDelimiter, precision, forcePrecision })
    ?.split(decimalDelimiter)
    .map((splitValue, index) =>
      index === 0 ? splitValue.replace(/\d(?=(?:\d{3})+(?!\d))/g, `$&${thousandDelimiter}`) : splitValue
    )
    .filter(value => !!value.length)
    .join(precision ? decimalDelimiter : '')

export const formatDifference = (diff: number) => `${diff < 0 ? '-' : '+'} ${toCurrency(Math.abs(diff))}`

export const formatPhoneNumber = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return ''
  }
  const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber, DEFAULT_COUNTRY_CODE as CountryCode)
  return parsedPhoneNumber?.formatNational() || ''
}

export const formatPhoneNumberURI = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return ''
  }
  const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber, DEFAULT_COUNTRY_CODE as CountryCode)
  return parsedPhoneNumber?.getURI() || ''
}

export const toPercentage = (
  fractionOrPercent?: string | number | null,
  { isFraction = false, precision = DEFAULT_PRECISION }: { isFraction?: boolean; precision?: number } = {}
) => {
  if (fractionOrPercent) {
    if (isFraction) {
      return `${(Number(fractionOrPercent) * 100).toFixed(precision)}%`
    }
    return `${Number(fractionOrPercent).toFixed(precision)}%`
  }
  return ''
}

export const getAddressString = (values: Partial<TAddressData>) =>
  Object.values(AddressField)
    .filter(field => !!values[field])
    .map(field => values[field])
    .join(', ')

export const generateDefaultTranslationString = (translateText: string) => `[DEFAULT STRING] ${translateText}`

export const padAccountNumber = (accountNumber: string) => {
  return accountNumber.padStart(8, '0')
}

export const absoluteDifference = (a: string, b: string): number => {
  // Empty strings get casted to zero, should return NaN in that case
  if (!a || !b) {
    return NaN
  }

  return Math.abs(Number(a) - Number(b))
}

export const isMoreThan = (a: string, b: string): boolean => Number(a) > Number(b)
