import { Link } from '@retire/components/Link/presentation'
import { Spacer } from '@retire/components/Spacer'
import { TransList } from '@retire/components/TransList'
import { PotName } from '@retire/constants/pots'
import { toPercentage } from '@retire/utils/strings'
import type { FC, ReactElement } from 'react'
import { useCallback, useRef } from 'react'

import { useFeatureFlag } from '../useFeatureFlag'
import { useFundDetails } from '../useFundDetails'
import { useModalWithTranslation } from '../useModalWithTranslation'
import { useTranslationWithOptions } from '../useTranslationWithOptions'

export const useFeesModal = (): { FeesModalLink: FC; FeesModal: () => ReactElement | null } => {
  const { Modal: FeesModal, onShowModal } = useModalWithTranslation()
  const modalOpenerRef = useRef<HTMLButtonElement>(null)
  // @TODO: https://smartpension.atlassian.net/browse/CM-1745
  const isTotalExpenseRatioEnabled = useFeatureFlag('enable_total_expense_ratio')

  const FeesModalLink: FC = ({ children }) => {
    const { t } = useTranslationWithOptions('common')
    // @TODO: https://smartpension.atlassian.net/browse/CM-1745
    const {
      fundDetails: {
        annualManagementCharge: flexibleIncomeAnnualManagementCharge,
        totalExpenseRatio: flexibleIncomeTotalExpenseRatio,
      },
      loading: flexibleIncomeFundDetailsLoading,
    } = useFundDetails(PotName.flexibleIncome)
    let flexibleIncomeFeePercentage: string | undefined
    if (isTotalExpenseRatioEnabled) {
      flexibleIncomeFeePercentage = toPercentage(flexibleIncomeTotalExpenseRatio)
    } else {
      flexibleIncomeFeePercentage = toPercentage(flexibleIncomeAnnualManagementCharge)
    }

    // @TODO: https://smartpension.atlassian.net/browse/CM-1745
    const {
      fundDetails: {
        annualManagementCharge: laterLifeAnnualManagementCharge,
        totalExpenseRatio: laterLifeTotalExpenseRatio,
      },
      loading: laterLifeFundDetailsLoading,
    } = useFundDetails(PotName.laterLife)
    let laterLifeFeePercentage: string | undefined
    if (isTotalExpenseRatioEnabled) {
      laterLifeFeePercentage = toPercentage(laterLifeTotalExpenseRatio)
    } else {
      laterLifeFeePercentage = toPercentage(laterLifeAnnualManagementCharge)
    }

    // @TODO: https://smartpension.atlassian.net/browse/CM-1745
    const {
      fundDetails: {
        annualManagementCharge: inheritanceAnnualManagementCharge,
        totalExpenseRatio: inheritanceTotalExpenseRatio,
      },
      loading: inheritanceFundDetailsLoading,
    } = useFundDetails(PotName.inheritance)
    let inheritanceFeePercentage: string | undefined
    if (isTotalExpenseRatioEnabled) {
      inheritanceFeePercentage = toPercentage(inheritanceTotalExpenseRatio)
    } else {
      inheritanceFeePercentage = toPercentage(inheritanceAnnualManagementCharge)
    }

    // @TODO: https://smartpension.atlassian.net/browse/CM-1745
    const {
      fundDetails: {
        annualManagementCharge: rainyDayAnnualManagementCharge,
        totalExpenseRatio: rainyDayTotalExpenseRatio,
      },
      loading: rainyDayFundDetailsLoading,
    } = useFundDetails(PotName.rainyDay)
    let rainyDayFeePercentage: string | undefined
    if (isTotalExpenseRatioEnabled) {
      rainyDayFeePercentage = toPercentage(rainyDayTotalExpenseRatio)
    } else {
      rainyDayFeePercentage = toPercentage(rainyDayAnnualManagementCharge)
    }

    const onHowWeCalculateYourFees = useCallback(() => {
      if (
        flexibleIncomeFundDetailsLoading ||
        laterLifeFundDetailsLoading ||
        inheritanceFundDetailsLoading ||
        rainyDayFundDetailsLoading
      ) {
        return
      }

      onShowModal({
        title: t('feesModal.title', 'Fees modal title'),
        content: '<0></0>',
        components: [
          <>
            <Spacer bottom="medium" />
            <TransList isOrdered>
              {t(
                'feesModal.steps',
                'Fees modal steps: flexibleIncomeFeePercentage: {{flexibleIncomeFeePercentage}}, laterLifeFeePercentage: {{laterLifeFeePercentage}}, inheritanceFeePercentage: {{inheritanceFeePercentage}}, rainyDayFeePercentage: {{rainyDayFeePercentage}}',
                {
                  flexibleIncomeFeePercentage,
                  laterLifeFeePercentage,
                  inheritanceFeePercentage,
                  rainyDayFeePercentage,
                }
              )}
            </TransList>
          </>,
        ],
        modalOpenerRef,
      })
    }, [
      flexibleIncomeFeePercentage,
      flexibleIncomeFundDetailsLoading,
      inheritanceFeePercentage,
      inheritanceFundDetailsLoading,
      laterLifeFeePercentage,
      laterLifeFundDetailsLoading,
      rainyDayFeePercentage,
      rainyDayFundDetailsLoading,
      t,
    ])

    return (
      <Link ref={modalOpenerRef} as="a" bold inline onClick={onHowWeCalculateYourFees}>
        {children}
      </Link>
    )
  }

  return {
    FeesModalLink,
    FeesModal,
  }
}
