import { Copy, Divider, Link } from '@retire/components'
import { DateFormat } from '@retire/constants'
import { usePublicSettings } from '@retire/hooks/usePublicSettings'
import { useSettings } from '@retire/hooks/useSettings'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { formatDate, toCurrency, toPercentage } from '@retire/utils'
import type { FC } from 'react'
import { useMemo } from 'react'
import { Trans } from 'react-i18next'

import { Section } from './components'
import type { TAccountSummaryTemplate } from './types'

const SectionDivider: FC = () => <Divider top="extraLarge" bottom="extraLarge" />

export const AccountSummaryTemplate: FC<TAccountSummaryTemplate> = ({
  personal,
  contact,
  policy,
  payment,
  security,
  beneficiaries,
  funds,
}) => {
  const { t } = useTranslationWithOptions('common')
  const {
    payrollSupport: { phoneNumber: PSDisplayPhoneNumber, email: PSEmail },
  } = useSettings()
  const {
    customerService: { phoneNumber: CSDisplayPhoneNumber, email: CSEmail },
  } = usePublicSettings()

  const CSEmailLink = <Link as="a" href={`mailto:${CSEmail}`} />
  const PSEmailLink = <Link as="a" href={`mailto:${PSEmail}`} />

  const fundsSummary = useMemo(
    () =>
      funds?.ltaAbolished
        ? t(
            'common:account.funds.samePclsAndDeathBenefitUsed',
            'The same amount of your lump sum and death benefit allowance was used.'
          )
        : undefined,
    [funds?.ltaAbolished, t]
  )

  const fundsDetails = useMemo(() => {
    if (!funds) {
      return null
    } else if (funds.ltaAbolished) {
      return [
        [
          {
            label: t('common:account.funds.fundsAtStart', 'Funds at start of plan'),
            value: toCurrency(funds.details.fundsAmountAtStart),
          },
          {
            label: t('common:account.funds.lumpSumAllowance', 'Lump sum allowance used at start'),
            value: toCurrency(funds.details.lumpSumAllowanceAmountAtStart),
            hasBottomDivider: true,
          },
          {
            label: t('common:account.funds.pclsTaken', 'Pension Commencement Lump Sum taken'),
            value: toCurrency(funds.details.taxFreeLumpSumAmount),
          },
          {
            label: t('common:account.funds.lumpSumAllowance', 'Lump sum allowance used'),
            value: toCurrency(funds.details.lumpSumAllowanceAmount),
          },
        ],
      ]
    } else {
      return [
        [
          {
            label: t('common:account.funds.fundsAtStart', 'Funds at start of plan'),
            value: toCurrency(funds.details.fundsAmountAtStart),
          },
          {
            label: t('common:account.funds.lifetimeAllowance', 'Lifetime allowance (LTA) % at start'),
            value: toPercentage(funds.details.lifetimeAllowancePercentAtStart, { isFraction: true }),
            hasBottomDivider: true,
          },
          {
            label: t('common:account.funds.pclsTaken', 'Pension Commencement Lump Sum taken'),
            value: toCurrency(funds.details.taxFreeLumpSumAmount),
          },
          {
            label: t('common:account.funds.lifetimeAllowance', 'Lifetime allowance (LTA) %'),
            value: toPercentage(funds.details.lifetimeAllowancePercent, { isFraction: true }),
          },
        ],
      ]
    }
  }, [funds, t])

  return (
    <>
      <Section
        title={personal.heading}
        summary={
          personal.showCustomerService && (
            <Trans
              components={{
                EmailLink: CSEmailLink,
              }}
            >
              {t(
                'account.common.customerService.personalDetails',
                'Personal details support email: {{email}}, phone number {{phoneNumber}}',
                {
                  email: CSEmail,
                  phoneNumber: CSDisplayPhoneNumber,
                }
              )}
            </Trans>
          )
        }
        details={[
          [
            {
              label: t('common:form.personalDetails.title.label', 'Title'),
              value: personal.details.title,
            },
            {
              label: t('common:account.personalDetails.fullName', 'Full name'),
              value: personal.details.fullName,
            },
            {
              label: t('common:form.personalDetails.bornOn.label', 'Born on'),
              value: personal.details.bornOn,
            },
            {
              label: t('common:form.personalDetails.gender.label', 'Gender'),
              value: t(`form.personalDetails.gender.value.${personal.details.gender}`, personal.details.gender || ''),
              twister: personal.details.genderInfo
                ? {
                    title: t('common:form.personalDetails.gender.twister.title', 'Gender info'),
                    children: (
                      <Copy as="p">
                        <Trans
                          components={{
                            EmailLink: CSEmailLink,
                          }}
                        >
                          {t(
                            'form.personalDetails.gender.twister.description',
                            'Gender info details. Email {{email}}, phone number {{phoneNumber}}',
                            {
                              email: CSEmail,
                              phoneNumber: CSDisplayPhoneNumber,
                            }
                          )}
                        </Trans>
                      </Copy>
                    ),
                  }
                : undefined,
            },
            {
              label: t('common:form.personalDetails.nino.label', 'NIN'),
              value: personal.details.nino,
            },
          ],
        ]}
        button={personal.button}
      />
      <SectionDivider />
      <Section
        title={contact.heading}
        details={[
          [
            {
              label: t('common:form.personalDetails.email.label', 'Email'),
              value: contact.details.email,
            },
            {
              label: t('common:form.contactDetails.address.label', 'Address'),
              value: contact.details.address,
            },
            {
              label: t('common:form.contactDetails.telephone.label', 'Telephone'),
              value: contact.details.phoneNumber,
            },
          ],
        ]}
        button={contact.button}
      />
      <SectionDivider />
      {policy && (
        <>
          <Section
            title={t('common:account.policyDetails.title', 'Policy details')}
            summary={
              <Trans
                components={{
                  EmailLink: CSEmailLink,
                }}
              >
                {t(
                  'account.common.customerService.policyDetails',
                  'Policy details support email: {{email}}, phone number {{phoneNumber}}',
                  {
                    email: CSEmail,
                    phoneNumber: CSDisplayPhoneNumber,
                  }
                )}
              </Trans>
            }
            details={[
              [
                {
                  label: t('common:account.policyDetails.policyNumber', 'Policy number'),
                  value: policy.details.policyNumber,
                },
              ],
            ]}
          />
          <SectionDivider />
        </>
      )}
      {/* payment details */}
      <Section
        title={payment.heading}
        summary={
          payment.showCustomerService && (
            <Trans
              components={{
                EmailLink: PSEmailLink,
              }}
            >
              {t(
                'account.common.customerService.paymentDetails',
                'Payment details support email: {{email}}, phone number {{phoneNumber}}',
                {
                  email: PSEmail,
                  phoneNumber: PSDisplayPhoneNumber,
                }
              )}
            </Trans>
          )
        }
        details={[
          [
            {
              label: t('common:account.paymentDetails.accountNumber', 'Account number'),
              value: '•••• ••••',
            },
            {
              label: t('common:account.paymentDetails.sortCode', 'Sort code'),
              value: '•• •• ••',
            },
          ],
        ]}
        button={payment.button}
      />
      <SectionDivider />
      {/* security details */}
      {security && (
        <>
          <Section
            title={t('common:account.security.title', 'Security')}
            details={[
              [
                {
                  label: t('common:account.security.password', 'Password'),
                  value: '••••••••',
                },
              ],
            ]}
            summary={
              <Trans
                components={[
                  <Link as="a" href={t('common:account.security.notification.url', 'Change password URL')} />,
                ]}
              >
                {t('common:account.security.notification.label', 'Change password notification')}
              </Trans>
            }
          />
          <SectionDivider />
        </>
      )}
      {/* beneficiaries */}
      <Section
        title={beneficiaries.heading}
        subTitle={beneficiaries.subTitle}
        twister={beneficiaries.twister}
        details={(beneficiaries.list || []).map((beneficiary, index) => [
          {
            label: t('common:beneficiary.ordinal', {
              count: index + 1,
              ordinal: true,
            }),
            value: beneficiary.name,
          },
          {
            label: t('common:form.personalDetails.bornOn.label', 'Beneficiary DOB'),
            value: (beneficiary.bornOn && formatDate(beneficiary.bornOn, DateFormat.date)) || '-',
          },
          {
            label: t('common:account.beneficiaries.relationship', 'Beneficiary relationship'),
            value: beneficiary.relation || '-',
          },
          {
            label: t('common:account.beneficiaries.share.label', 'Beneficiary share'),
            value: toPercentage(beneficiary.percentage),
          },
        ])}
        button={beneficiaries.button}
      />
      {fundsDetails && (
        <>
          <SectionDivider />
          <Section
            title={t('common:account.funds.title', 'Funds')}
            subTitle={t('common:account.funds.caption', 'Funds caption')}
            details={fundsDetails}
            summary={fundsSummary}
          />
        </>
      )}
    </>
  )
}
